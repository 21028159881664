/**
 * components/typography.css
 *
 * Typography rules.
 *
 */

html {
  font-family: $fontText;
  background: $dark;
  color: #fff;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  background: $dark;
  color: #fff;
}

p,
p a {
  font-weight: 600;
  color: #fff;
}

p strong {
  font-weight: 800;
}

a {
  color: inherit;
  text-decoration: none;
}

.big-title {
  padding: 0;
  margin: 0;

  width: 100%;
  font-size: 22px;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;

  & strong {
    color: $colorAccent1;
    display: inline;
    font-weight: 800;
  }

  br {
    display: none;
  }

  @media (min-width: 699px) {
    font-size: 32px;

    br {
      display: block;
    }
  }

  @media (min-width: 1199px) {
    font-size: 48px;
  }
}

.medium-title {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0 0 40px 0;

  font-size: 18px;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;

  br {
    display: none;
  }

  &.with-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;

    @media (min-width: 699px) {
      font-size: 20px;
    }

    @media (min-width: 1199px) {
      font-size: 24px;
    }
  }

  @media (min-width: 699px) {
    font-size: 26px;

    br {
      display: block;
    }
  }

  @media (min-width: 1199px) {
    font-size: 36px;
  }

  & strong {
    color: $colorAccent1;
  }

  &:before {
    position: absolute;
    left: 0;
    bottom: 0;

    transform: translate(0, 20px);

    content: '';
    width: 0;
    height: 5px;
    background: rgba(#fff, 0.08);
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;

    transform: translate(0, 20px);

    content: '';
    width: 0;
    height: 5px;
    background: $colorAccent1;
  }

  &.is-center {
    &:before {
      left: 50%;
      transform: translate(-50%, 20px)
    }

    &:after {
      left: 50%;
      transform: translate(-50%, 20px)
    }
  }
}

.small-title {
  padding: 0;
  margin: 0;

  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;

  & strong {
    color: $colorAccent1;
  }
}

.medium-text {
  padding: 0;
  margin: 0;

  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;

  strong {
    color: #fff;
    font-weight: 800;
  }

  br {
    display: none;
  }

  @media (min-width: 699px) {
    font-size: 20px;

    br {
      display: block;
    }
  }

  @media (min-width: 1199px) {
    font-size: 22px;
  }
}

.regular-text {
  padding: 0;
  margin: 0;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  color: $light;

  br {
    display: none;
  }

  @media (min-width: 699px) {
    font-size: 15px;

    br {
      display: block;
    }
  }

  @media (min-width: 1199px) {
    font-size: 16px;
  }

  & strong {
    color: #fff;
    font-weight: 700;
  }
}

.small-text {
  padding: 0;
  margin: 0;

  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  color: $light;

  br {
    display: none;
  }

  @media (min-width: 699px) {
    font-size: 15px;

    br {
      display: block;
    }
  }

  @media (min-width: 1199px) {
    font-size: 16px;
  }

  & strong {
    color: $colorAccent1;
  }
}


.is-inview {
  h1, h2, h3, h4, h5, h6 {
    &:before {
      width: 80px;
      transition: width 0.45s ease;
      transition-delay: 300ms;
    }

    &:after {
      width: 80px;
      transition: width 0.45s ease;
      transition-delay: 0.60s;
    }
  }
}
