
.form {
  width: 100;
  margin-top: 35px;

  input[type="text"],
  input[type="email"],
  input[type="number"],
  select,
  .flatpickr-mobile {
    width: 100%;
    height: 50px;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 14px;
    -webkit-appearance: none;
    border:0;
    margin-bottom: 10px;
    background-color: $grey;
    color: #fff;
    font-family: $fontText !important;

    &::placeholder {
      color: rgba(#fff, 0.50);
    }
  }

  .flatpickr-mobile {
    background-image: none;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    height: 150px;
    max-height: 150px;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: 14px;
    -webkit-appearance: none;
    border:0;
    margin-bottom: 5px;
    background-color: $grey;
    color: #fff;
    font-family: $fontText !important;

    &::placeholder {
      color: rgba(#fff, 0.50);
    }
  }

  select {
    background-image: url(/img/site/arrow-down.svg);
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    padding-right: 40px;
  }

  input[type="submit"] {
    border:0;
    margin-top: 20px;
  }
}

.center-col {
  input[type="submit"] {
    display: block;
    margin: 20px auto 0 auto;
  }
}

.form-message {
  padding: 20px;
  background: #fff;
  border-radius: 50px;
  line-height: 1;
  margin: 35px 0 0 0;
  color: green;

  &.error {
    color: red;
  }
}

input#address1 { display: none; }

.flatpickr-input {
  background-image: url('/img/site/calendar-icon.svg');
  background-position: right 20px center;
  background-repeat: no-repeat;
}

.add-more {
  margin: 25px 0;
}

.upload-zone {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  flex-wrap: wrap;

  .upload-item {
    position: relative;
    width: calc(96% / 2);
    text-align: center;
    background-color: $grey;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;

    @media (min-width: 699px) {
      width: calc(96% / 3);
      padding: 20px;
    }

    .upload-image {
      position: relative;
      cursor: pointer;
      transition: opacity 0.35s ease-in-out;

      &:hover {
        opacity: 0.55;
      }

      label.is-active,
      .filename.is-active {
        color: $colorAccent1;
      }

      &.is-active {
        color: $colorAccent1;
      }
    }

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    input[type="file"] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }

    label {
      color: #9D9D9D;
      font-size: 12px;

      @media (min-width: 699px) {
        font-size: 16px;
      }
    }

    input {
      background-color: #9D9D9D;
      margin: 0 auto;
      text-align: center;
    }

    .separator {
      position: relative;

      span {
        position: relative;
        z-index: 2;
        display: inline-block;
        padding: 5px;
        background-color: $grey;
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        height: 2px;
        width: 100%;
        background-color: #fff;
      }
    }
  }
}

.mobile-only {
  display: block;
  margin-bottom: 5px;

  @media (min-width: 699px) {
    display: none;
  }
}
