/**
 * components/global.css
 *
 * Project-wide styles
 *
 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

.fluid-container {
  padding: 15px 20px;

  @media (min-width: 699px) {
    padding: 15px 25px;
  }

  @media (min-width: 1199px) {
    padding: 15px 30px;
  }
}

.max-container {
  max-width: 1180px;
  margin: 0 auto;
}

::selection {
  background-color: $colorAccent1;
  color: #fff;
}

::-moz-selection {
  background-color: $colorAccent1;
  color: #fff;
}
