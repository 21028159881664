/*! Site Buttons
---------------------------------------------- */
.site-btn {
  display: inline-block;
  padding: 1rem 1.65rem;
  width: 100%;
  margin-bottom: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid $colorAccent1;
  text-decoration: none;
  background: $colorAccent1;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;

  transition: all 0.6s ease;

  @media (min-width: 699px) {
    padding: 15px 20px;
    font-size: 1rem;
    width: auto;
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.75;
    transform: scale(0.975);
  }

  &.is-white {
    background: #fff;
    color: $colorAccent1;
    border-color: #fff;

    &:hover {
      background: #fff;
      color: $colorAccent1;
      opacity: 1;
    }
  }

  &.is-outline {
    background: none;
    color: #fff;
    border-color: #fff;

    &:hover {
      background: #fff;
      color: $colorAccent1;
      opacity: 1;
    }
  }
}
