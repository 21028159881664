/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */

/*! Page Header and Navigation
---------------------------------------------- */
main > .container {
  padding-top: 60px;

  @media (min-width: 699px) {
    padding-top: 75px;
  }
}

.page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 99;

  background: $dark;
  border-bottom: 1px solid rgba(#fff, 0.10);

  transform: translate3d(0,0,0);
  transition: transform .5s ease-in-out;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}


html[data-direction="down"] .page-header {
  transform: translate3d(0,-100%,0);
}

.main-nav {
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: flex-start;

  .logo {
    margin-right: 30px;

    img {
      display: block;
      width: 100%;
      max-width: 145px;

      @media (min-width: 699px) {
        max-width: 210px;
      }
    }
  }

  .nav-links,
  .nav-links-list {
    display: flex;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;

    padding: 0;

    color: #fff;
    text-transform: uppercase;
    font-weight: 700;

    .nav-link-item {
      display: none;
      margin-right: 30px;
      font-size: 16px;

      transition: all 0.6s ease;

      @media (min-width: 1199px) {
        display: inline-block;
      }

      &:hover,
      &.is-active {
        color: $colorAccent1;
      }
    }
  }

  .nav-ctas {
    color: #fff;
    font-weight: 700;
    margin-left: auto;

    display: none;

    @media (min-width: 1199px) {
      display: block;
    }

    a:not(.site-btn) {
      transition: all 0.6s ease;

      &:hover {
        color: $colorAccent1;
      }
    }
  }

  .nav-cta {
    display: none;
    margin-left: 30px;

    @media (min-width: 699px) {
      display: inline-block;
    }
  }

  .separator {
    margin: 0 5px;
  }

  .lang {
    margin: 0 0 0 0;
  }
}

.extra-link {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 0 0 0;

  @media (min-width: 1199px) {
    display: inline;
    width: auto;
    margin: 0 0 0 25px;
  }
}

.hide-small-screen {
	@media only screen and (max-width: 1250px) {
		display: none;
	}
}

.mobile-menu-wrap {
	width: 100%;
	position: relative;
	z-index: -1000;
	transition: all 0.2s ease;

	&.open {
		z-index: 5;

		.overlay {
			opacity: 1;
		}
		.mobile-menu {
			right: 0;
		}
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(52,67,83,0.8);
		z-index: 800;
		opacity: 0;
		transition: all 0.2s ease;
	}

	.mobile-menu {
		position: fixed;
		top: 65px;
		right: -100%;
		width: 40vw;
		height: calc(100vh - 65px);
		background-color: $grey;
		z-index: 900;
		padding: 60px 40px;
		transition: all 0.2s ease;

		@media only screen and (max-width: 900px) {
			width: 50vw;
		}
		@media only screen and (max-width: 700px) {
			width: 60vw;
		}
		@media only screen and (max-width: 550px) {
			width: 100vw;
			padding: 40px 30px;
		}
	}

	.top-links {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;

		a {
			color: #fff;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;

			&:last-child {
				font-size: 18px;
			}
		}
	}

	.main-mobile-nav {
		width: 100%;
    margin-bottom: 30px;

		a {
			width: 100%;
			display: block;
			color: #fff;
			text-transform: uppercase;
			font-size: 14px;
			margin-bottom: 10px;
			font-weight: 700;
			background-color: rgba($colorAccent1, 0.55);
			padding: 15px 20px;
			border-radius: 3px;

			&.active {
				background-color: rgba($colorAccent1, 1);
			}
		}
	}

	.site-btn {
		width: 100%;
		text-align: center;
	}

}

	#hamburger {
		display: block;

		@media (min-width: 1199px) {
      display: none;
    }
	}

	#hamburger {
	  width: 30px;
	  height: 20px;
	  position: relative;
    margin-left: auto;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .5s ease-in-out;
	  -moz-transition: .5s ease-in-out;
	  -o-transition: .5s ease-in-out;
	  transition: .5s ease-in-out;
	  cursor: pointer;
	}

	#hamburger span {
	  display: block;
	  position: absolute;
	  height: 4px;
	  width: 100%;
	  background: $colorAccent1;
	  border-radius: 9px;
	  opacity: 1;
	  left: 0;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .25s ease-in-out;
	  -moz-transition: .25s ease-in-out;
	  -o-transition: .25s ease-in-out;
	  transition: .25s ease-in-out;
	}

	#hamburger span:nth-child(1) {
	  top: 0px;
	}

	#hamburger span:nth-child(2),#hamburger span:nth-child(3) {
	  top: 8px;
	}

	#hamburger span:nth-child(4) {
	  top: 16px;
	}

	#hamburger.open span:nth-child(1) {
	  top: 18px;
	  width: 0%;
	  left: 50%;
	}

	#hamburger.open span:nth-child(2) {
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	}

	#hamburger.open span:nth-child(3) {
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	}

	#hamburger.open span:nth-child(4) {
	  top: 18px;
	  width: 0%;
	  left: 50%;
	}

/*! Hero
---------------------------------------------- */
.block-hero {
  position: relative;
  text-align: center;
  background: $grey;
  height: 36vw;
  min-height: 405px;
  max-height: 600px;

  background: url('/img/site/metonor-hero.jpg');
  background-position: center;
  background-size: cover;

  .back-img-effect {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(#000, 0.60);
    border-top: 1px solid rgba(#fff, 0.08);
    border-bottom: 1px solid rgba(#fff, 0.08);
  }

  .hero-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0 20px;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  .hero-icon {
    margin-bottom: 25px;
    max-width: 75px;
  }

  .hero-title {
    margin-bottom: 25px;
  }

  .hero-text {
    max-width: 800px;
    margin: 0 auto;
  }

  .site-btn {
    margin-top: 35px;
    margin-bottom: 55px;
  }

  .scroller {
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 2;
    transform: translate(-50%, 50%);

    width: 60px;
    height: 60px;

    background: $dark;
    border: 1px solid rgba(#fff, 0.15);
    border-radius: 50%;

    text-align: center;
    line-height: 60px;

    img {
      display: inline-block;
      width: 15px;
      z-index: -1;
      position: relative;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}

/*! Argument
---------------------------------------------- */
.block-arguments {
  position: relative;
  text-align: center;
  padding-top: 45px;
  padding-bottom: 45px;

  overflow: hidden;

  @media (min-width: 699px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .arguments-intro {
    .argument-text {
      max-width: 750px;
      margin: 0 auto;
      margin-bottom: 75px;
    }
  }

  .arguments-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: stretch;
    justify-content: center;

    flex-wrap: wrap;

    .argument-item {
      position: relative;
      width: 100%;
      background: $grey;
      padding: 20px;
      padding-top: 55px;
      padding-bottom: 55px;
      margin-bottom: 65px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: 699px) {
        width: calc(96% / 3);
        padding: 20px;
        padding-top: 55px;
        padding-bottom: 35px;
        margin-bottom: 65px;
        margin: 0.5%;
      }

      @media (min-width: 1199px) {
        padding: 20px;
        padding-top: 75px;
      }

      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: rgba(#fff, .15);
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: $colorAccent1;
        z-index: 2;
      }

      &.is-inview {
        &:before {
          width: 100%;
          transition: width 0.45s ease;
          transition-delay: 150ms;
        }

        &:after {
          width: 100%;
          transition: width 0.45s ease;
          transition-delay: 0.45s;
        }
      }

      .argument-item-icon {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 2;
        transform: translate(-50%, -50%);

        width: 80px;
        height: 80px;

        background: #fff;
        padding: 15px;
        border: 1px solid rgba(#fff, 0.15);
        border-radius: 50%;

        text-align: center;
        line-height: 1.1;
        text-indent: -7px;

        .number {
          position: relative;
          left: 3px;
          color: $colorAccent1;
          text-transform: none;
          font-style: normal;
          font-weight: 800;
          font-size: 40px;
          text-align: center;
          line-height: 42px;
        }
      }

      .argument-item-title {
        margin-bottom: 25px;
      }
    }

    &.is-4 {
      .argument-item {
        padding-bottom: 55px;

        @media (min-width: 699px) {
          width: calc(96% / 3);
          padding: 20px;
          padding-top: 55px;
          padding-bottom: 35px;
          margin: 0.5%;
          margin-bottom: 55px;
        }

        @media (min-width: 1199px) {
          width: calc(96% / 4);
        }

        .small-title {
          font-size: 16px;

          @media (min-width: 699px) {
            font-size: 20px;
          }
        }

      }
    }
  }
}

/*! Split services
---------------------------------------------- */
.block-split {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: 699px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .split-service-item {
    position: relative;
    z-index: 3;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    margin-bottom: 75px;

    @media (min-width: 699px) {
      margin-bottom: 245px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.is-alternate {
      flex-direction: row-reverse;

      .service-item-content {
        padding-left: 0;
        padding-right: 0;

        @media (min-width: 699px) {
          padding-right: 45px;
        }
      }

      .service-item-image {
        padding-right: 0;
        padding-left: 0;

        @media (min-width: 699px) {
          padding-left: 45px;
        }
      }
    }

    > div{
      width: 100%;

      @media (min-width: 699px) {
        width: 50%;
      }
    }
  }

  .service-item-image {
    padding-right: 0;
    margin-bottom: 25px;

    @media (min-width: 699px) {
      padding-right: 45px;
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;

      max-width: 275px;
      margin: -10px auto 15px auto;

      @media (min-width: 699px) {
        max-width: inherit;
        margin:0;
      }
    }
  }

  .service-item-content {
    padding-left: 0;

    @media (min-width: 699px) {
      padding-left: 45px;
    }

    .regular-text {
      padding-bottom: 25px;

      a {
        display: inline-block;
        margin-top: 10px;
      }
    }
  }

  .service-item-btns {
    .site-btn {
      margin-right: 15px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

/*! About
---------------------------------------------- */
.block-about {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: 699px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .about-intro {
    text-align: center;
    margin-top: 45px;

    @media (min-width: 1199px) {
      margin-top: 75px;
    }

    .about-text {
      max-width: 750px;
      margin: 0 auto;
      margin-bottom: 15px;

      @media (min-width: 699px) {
        margin-bottom: 25px;
      }
    }
  }

  .about-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    background: $grey;

    > div {
      width: 100%;

      @media (min-width: 699px) {
        width: 50%;
      }
    }

    .about-image {
      img {
        display: block;
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }

    .about-content {
      position: relative;
      padding: 20px;
      background: $grey;

      @media (min-width: 699px)  {
        padding: 35px;
      }

      @media (min-width: 1199px)  {
        padding: 75px;
      }

      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: rgba(#fff, .15);
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: $colorAccent1;
        z-index: 2;
      }

      .about-title.with-icon {
        margin-top: 25px;
        margin-bottom: 15px;

        @media (min-width: 699px) {
          margin-top: 0;
          margin-bottom: 25px;
        }

        &:before, &:after {
          display: none;
        }
      }

      &.is-inview {
        &:before {
          width: 100%;
          transition: width 0.45s ease;
          transition-delay: 150ms;
        }

        &:after {
          width: 100%;
          transition: width 0.45s ease;
          transition-delay: 0.45s;
        }
      }

      .about-icon {
        margin-right: 10px;

        display: inline-block;

        width: 45px;
        height: 45px;

        background: $colorAccent1;
        padding: 5px;
        border: 1px solid rgba(#fff, 0.15);
        border-radius: 50%;

        text-align: center;
        line-height: 1.2;

        .number {
          color: #fff;
          text-transform: none;
          font-style: normal;
          font-weight: 800;
          font-size: 25px;
          text-align: center;
        }
      }

      .regular-text {
        padding-bottom: 25px;

        a {
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }
}

.block-about.is-alternate {
  .about-container {
    flex-direction: row-reverse;
  }
}

.block-about.less-padding {
  padding-top: 25px;
  padding-bottom: 25px;

  &#soumission {
    padding-top: 45px;

    @media (min-width: 699px) {
      padding-top: 75px;
    }
  }
}

/*! App
---------------------------------------------- */
.block-app {
  position: relative;
  z-index: 2;
  background: $grey;
  margin-top: 0;

  @media (min-width: 699px) {
    margin-top: 125px;
  }

  .app-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: $grey;

    flex-wrap: wrap;

    > div {
      width: 100%;

      @media (min-width: 699px) {
        width: calc(100% / 3);
      }
    }

    .app-left-content {
      padding: 20px;
      padding-left: 0;
      background: $grey;

      order: 3;

      @media (min-width: 699px) {
        padding: 45px;
        padding-left: 0;

        order: 1;
      }

      .site-btn {
        display: none;

        @media (min-width: 699px) {
          display: inline-block;
        }
      }
    }

    .app-middle-content {
      margin-top: 35px;
      margin-bottom: 35px;

      order: 1;

      @media (min-width: 699px) {
        margin-top: -125px;
        margin-bottom: -125px;

        order: 2;
      }

      img {
        display: block;
        width: 100%;
        max-width: 255px;
        margin: 0 auto;

        @media (min-width: 699px) {
          max-width: 350px;
        }
      }
    }

    .app-right-content {
      padding: 20px;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      background: $grey;

      order: 4;

      @media (min-width: 699px) {
        padding: 45px;
        padding-right: 0;
        padding-top: 45px;
        order: 3;
      }

      .site-btn {
        display: inline-block;

        @media (min-width: 699px) {
          display: none;
        }
      }
    }

    .regular-text {
      padding-bottom: 25px;

      a {
        display: inline-block;
        margin-top: 10px;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      img {
        margin-right: 15px;
      }

      li {
        display: flex;
        align-items: center;
      }
    }
  }
}

/*! Stats
---------------------------------------------- */
.block-stats {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: 699px) {
    padding-top: 0;
    padding-bottom: 75px;
  }

  .stats-intro {
    text-align: center;

    .stats-text {
      max-width: 750px;
      margin: 0 auto;
      margin-bottom: 15px;

      @media (min-width: 699px) {
        margin-bottom: 25px;
      }
    }
  }

  .stats-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    .stats-item {
      position: relative;
      width: 100%;
      padding: 30px;
      margin-bottom: 15px;
      text-align: center;
      border: 1px solid rgba(#fff, 0.10);

      @media (min-width: 699px) {
        width: calc(96% / 3);
        padding: 20px;
        margin-bottom: 0;
      }

      @media (min-width: 1199px) {
        padding: 50px;
      }

      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: rgba(#fff, .15);
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: $colorAccent1;
        z-index: 2;
      }
    }

    .stats-heading {
      margin: 0;
      padding: 0 0 10px 0;
      font-weight: 700;
      font-size: 20px;
    }

    .stats-price {
      margin: 0;
      padding: 0;
      font-size: 32px;
      color: $colorAccent1;
      font-weight: 400;

      sub {
        position: relative;
        top: -1px;
        display: inline-block;
        color: #fff;
        font-size: 65%;
        font-weight: 400;
        padding-left: 3px;
      }
    }
  }
}

/*! Big Stats
---------------------------------------------- */
.block-big-stats {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: 699px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .big-stats-intro {
    text-align: center;

    .big-stats-text {
      max-width: 750px;
      margin: 0 auto;
      margin-bottom: 15px;

      @media (min-width: 699px) {
        margin-bottom: 25px;
      }
    }
  }

  .big-stats-container {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    .big-stats-item {
      position: relative;
      width: calc(96% / 2);
      padding: 20px;
      margin-bottom: 15px;
      text-align: center;

      @media (min-width: 699px) {
        width: calc(96% / 4);
        padding: 20px;
        margin-bottom: 0;
      }

      @media (min-width: 1199px) {
        padding: 50px 20px;
      }

      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: rgba(#fff, .15);
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 0;
        background-color: $colorAccent1;
        z-index: 2;
      }
    }

    .big-stats-heading {
      margin: 0;
      padding: 0 0 0 0;
      font-weight: 700;
      font-size: 16px;

      @media (min-width: 699px) {
        font-size: 18px;
      }
    }

    .big-stats-number {
      margin: 0 0 10px 0;
      padding: 0;
      font-size: 45px;
      color: $colorAccent1;
      font-weight: 800;

      @media (min-width: 699px) {
        font-size: 75px;
      }

      sub {
        position: relative;
        top: -1px;
        display: inline-block;
        color: #fff;
        font-size: 65%;
        font-weight: 400;
        padding-left: 3px;
      }
    }
  }
}

/*! Footer
---------------------------------------------- */
.block-cta {
  position: relative;

  &.margin-top {
    margin-top: 75px;
  }

  .cta-container {
    padding: 25px;
    background: $colorAccent1;
    color: #fff;
    text-align: center;

    @media (min-width: 699px) {
      padding: 35px;
    }

    @media (min-width: 1199px)  {
      padding: 75px;
    }
  }

  .cta-icon {
    margin-bottom: 25px;
  }

  .cta-text {
    max-width: 750px;
    margin: 0 auto;
    margin-bottom: 15px;

    @media (min-width: 699px) {
      margin-bottom: 25px;
    }
  }

  .cta-btns {
    .site-btn {
      margin: 0 8px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.is-alternate .cta-container {
    background: $grey;
    color: #fff;

    .is-center {
      margin-bottom: 75px;
    }

    .cta-text {
      color: #fff;
    }
  }
}

/*! Block Images
---------------------------------------------- */
.block-images {
  position: relative;
  padding-top: 45px;
  padding-bottom: 0;

  @media (min-width: 699px) {
    padding-top: 105px;
    padding-bottom: 10px;
  }

  .block-images-title {
    margin-bottom: 0;
    margin-top: 15px;

    @media (min-width: 699px) {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  .block-images-intro {
    text-align: center;

    .block-images-text {
      max-width: 750px;
      margin: 0 auto;
      margin-bottom: 15px;

      @media (min-width: 699px) {
        margin-bottom: 25px;
      }
    }
  }

  .images-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    .image-item {
      width: calc(92% / 2);
      margin-bottom: 35px;
      text-align: center;

      @media (min-width: 699px) {
        width: calc(92% / 3);
      }

      @media (min-width: 1199px) {
        width: calc(90% / 5);
      }

      .image-thumb {
        border-radius: 50%;
        margin-bottom: 15px;
        border: 4px solid #fff;
      }

      img {
        display: block;
        width: 100%;
        border-radius: 50%;
        margin-bottom: 15px;
      }

      .icon-wrapper {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 110px;
        margin: 0 auto;
        border-radius: 50%;
        margin-bottom: 15px;
        padding: 0;
        border: 4px solid #fff;
        text-align: center;

        .image-icon {
          display: inline-block;
          width: 60%;
          border-radius: none;
          margin: 0;
        }
      }

      .image-heading {
        margin-bottom: 15px;

        font-size: 16 px;

        @media (min-width: 699px) {
          font-size: 22px;
        }
      }

      .image-text {
        line-height: 1.7;
        color: #9D9D9D;
      }
    }
  }
}

/*! Block Prices
---------------------------------------------- */
.block-prices {
  position: relative;
  padding-top: 45px;
  padding-bottom: 0;

  @media (min-width: 699px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .block-prices-intro {
    text-align: center;

    .block-prices-text {
      max-width: 750px;
      margin: 0 auto;
      margin-bottom: 15px;

      @media (min-width: 699px) {
        margin-bottom: 25px;
      }
    }
  }

  .prices-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    padding-top: 45px;

    .prices-item {
      width: 100%;
      margin-bottom: 15px;

      @media (min-width: 699px) {
        width: calc(92% / 3);
        margin-bottom: 0;
      }

      .price-heading {
        background: $grey;
        color: #fff;
        padding: 10px 20px;
        font-size: 20px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          font-weight: 300;
          border: 1px solid $grey;
          margin-top: -1px;

          .item-heading {
            color: #9D9D9D;
          }

          .item-price {
            color: $colorAccent1;
          }
        }
      }
    }
  }
}

/*! Block Contact
---------------------------------------------- */
.block-contact {
  position: relative;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: 699px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .contact-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    flex-wrap: wrap;

    .contact-heading {
      width: 100%;
      text-align: center;
      margin-top: 35px;

      @media (min-width: 699px) {
        text-align: left;
        margin-top: 0;
      }
    }

    .contact-col {
      width: 100%;

      @media (min-width: 699px) {
        width: 50%;
      }
    }

    .center-col {
      margin: 0 auto;

      width: 100%;

      @media (min-width: 699px) {
        width: 50%;
      }
    }

    .contact-intro {
      text-align: center;

      .contact-heading {
        text-align: center;
        font-weight: 400;
        line-height: 1.2;
      }

      .contact-number {
        margin-bottom: 45px;
        a {
          color: $colorAccent1;
          font-size: 30px;
        }
      }
    }

    .contact-split {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      flex-wrap: wrap;

      flex-direction: column-reverse;

      @media (min-width: 699px) {
        flex-direction: row;
      }

      .visual {
        position: relative;
        width: 100%;
        margin-top: 45px;

        @media (min-width: 699px) {
          width: 35%;
          margin-top: 0;
        }

        img {
          display: block;
          width: 100%;
          max-width: 200px;
          margin: 0 auto;

          @media (min-width: 699px) {
            padding-right: 45px;
            max-width: inherit;
          }
        }
      }

      .form-container {
        width: 100%;

        @media (min-width: 699px) {
          width: calc(100% - 35%);
          padding-left: 45px;
          padding-right: 45px;
        }

        .form-heading {
          padding-top: 0;

          @media (min-width: 1199px) {
            padding-top: 75px;
          }
        }

        .form-text {
          color: #fff;
        }
      }
    }

    .contact-form {
      padding-right: 0;

      @media (min-width: 699px) {
        padding-right: 45px;
      }
    }
  }

  .opening-hours {
    background: rgba(#fff, 0.123);
    padding: 10px;
    border-radius: 6px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  /* Set a size for our map container, the Google Map will take up 100% of this container */
  .map-wrapper {
    position: relative;
    min-height: 400px;
    margin-top: 35px;
    border: 1px solid rgba(#fff, 0.25);
  }

  #map {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

/*! Footer
---------------------------------------------- */
.block-footer {
  position: relative;
  padding: 75px 0;
  padding-top: 75px;

  background-position: center;
  background-size: cover;

  @media (min-width: 699px) {
    padding: 135px 0;
    padding-top: 200px;
  }

  @media (min-width: 1199px)  {
    padding: 175px 0;
    padding-top: 245px;
  }

  &.is-smaller {
    @media (min-width: 699px) {
      padding: 125px 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(#000, 0.6);
    border-top: 1px solid rgba(#fff, 0.08);
    border-bottom: 1px solid rgba(#fff, 0.08);
  }

  .scroller {
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 2;
    transform: translate(-50%, 50%);

    width: 60px;
    height: 60px;

    background: $dark;
    border: 1px solid rgba(#fff, 0.15);
    border-radius: 50%;

    text-align: center;
    line-height: 60px;

    img {
      display: inline-block;
      width: 15px;
      z-index: -1;
      position: relative;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  .footer-container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    flex-wrap: wrap;

    .footer-main-icon {
      margin: 0 auto;
      margin-bottom: 25px;
    }

    .footer-heading {
      margin-bottom: 45px;
    }

    .regular-text {
      color: #fff;
      font-weight: 700;
    }
  }

  .footer-col {
    width: 100%;
    padding: 0 0;
    margin-bottom: 25px;
    text-align: center;

    br {
      display: block;
    }

    @media (min-width: 699px) {
      width: calc(100% / 3);
      padding: 0 35px;
      margin-bottom: 0;
      text-align: center;
    }

    @media (min-width: 1199px) {
      padding: 0 75px;
    }

    a {
      transition: all 0.6s ease;

      &:hover {
        color: $colorAccent1;

        strong {
          color: #fff;
        }
      }
    }
  }

  .footer-icon {
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
    width: 80px;
    height: 80px;

    background: #fff;
    padding: 15px;
    border: 1px solid rgba(#fff, 0.15);
    border-radius: 50%;

    text-align: center;
    line-height: 1.1;
    text-indent: -7px;
  }
}

.footer-copy {
  position: relative;
  margin: 0;
  margin-top: -1px;
  padding-top: 0;
  padding-bottom: 0;
  background: $dark;

  border-top: 1px solid rgba(#fff, 0.08);

  p {
    margin: 0;
    padding: 0;
  }
}

.copy-container {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  > div {
    width: 100%;
    text-align: center;
    padding: 20px;

    @media (min-width: 699px) {
      text-align: left;
      width: calc(100% / 3);
      padding: 0;
    }
  }

  .col-middle {
    margin-left: auto;
    margin-right: auto;

    order: 3;

    @media (min-width: 699px) {
      order: 0;
    }

    img {
      display: block;
      width: 100%;
      max-width: 325px;
      margin: 0 auto;
    }
  }

  .col-right {
    text-align: center;

    @media (min-width: 699px) {
      text-align: right;
    }
  }
}


.visual-bg-1 {
  position: absolute;
  top: 0;
  left: -180px;
  transform: translate(0, -40%);
  width: 100%;
  max-width: 570px;
  opacity: 0.80;

  display: none;

  @media (min-width: 699px) {
    display: block;
  }
}

.visual-bg-2 {
  position: absolute;
  top: 0;
  right: -350px;
  transform: translate(0, 0);
  z-index: -1;
  width: 100%;
  max-width: 750px;
  opacity: 0.80;

  display: none;

  @media (min-width: 699px) {
    display: block;
  }
}

#page-intro {
  position: relative;
}


.site-spacer-bottom {
  display: block;
  position: relative;
  width: 100%;
  height: 35vh;
}
